// @flow
import Promise from 'bluebird';

import APIService, { API_VERSION } from 'services/APIService';
import CachedMapService from 'services/wip/CachedMapService';
import CategoryService, {
  FieldCategoryService,
} from 'services/wip/CategoryService';
import DimensionService from 'services/wip/DimensionService';
// No way to avoid this circular dependency unfortunately.
// eslint-disable-next-line import/no-cycle
import FieldMetadata from 'models/core/wip/FieldMetadata';
import FieldService from 'services/wip/FieldService';
import { convertIDToURI, convertURIToID } from 'services/wip/util';
import { getConstituentIds } from 'components/AdvancedQueryApp/QueryFormPanel/QueryBuilder/FieldCustomizationModule/useFieldConstituents';
import type { APIVersion, HTTPService } from 'services/APIService';
import type { Cache, RejectFn, ResolveFn } from 'services/wip/CachedMapService';
import type { URI, URIConverter } from 'services/types/api';

/**
 * The FieldMetadataService is used to retrieve all the calculable FieldMetadata objects that exist.
 */
class FieldMetadataService extends CachedMapService<FieldMetadata>
  implements URIConverter {
  apiVersion: APIVersion = API_VERSION.V2;
  endpoint: string = 'query/field_metadata';
  _httpService: HTTPService;

  constructor(httpService: HTTPService) {
    super();
    this._httpService = httpService;
  }

  buildCache(
    resolve: ResolveFn<FieldMetadata>,
    reject: RejectFn,
  ): Promise<Cache<FieldMetadata>> {
    // Performance optimization: preload services needed during deserialization
    // of the field metadata so that we can use the
    // FieldMetadata.UNSAFE_deserialize synchronous deserialization method.
    // If we use FieldMetadata.deserializeAsync, thousands of promises will be
    // created, and the resolution of all those promises is slow and noticeable.
    // Using the UNSAFE_deserialize method is preferred in this case.
    const promise = Promise.all([
      this._httpService.get(this.apiVersion, this.endpoint),
      CategoryService.getAll(),
      DimensionService.getAll(),
      FieldCategoryService.getAll(),
      FieldService.getAll(),
    ]);
    return promise
      .then(([rawFieldMetadataList]) => {
        const fieldMetadataMappingCache = {};
        // NOTE(nina): When we called serialize() on the frontend
        // FieldMetadata model, we stored it as a ref, so that we wouldn't
        // ever point to a category or source id that no longer exists.
        // If we called UNSAFE_deserialize, this would call UNSAFE_forceGet
        // and will result in infinite recursion/immediate crash. Models
        // that only store the JSONRef as the serialized value must
        // instead call [Model].create(), and deserialize the inner
        // properties as needed.
        rawFieldMetadataList.forEach(
          ({ copiedFromFieldId, description, id, sourceName }) => {
            const field = FieldService.UNSAFE_forceGet(id);
            const categoryId = field
              .fieldCategoryMappings()
              .first()
              .categoryId();
            const category = FieldCategoryService.UNSAFE_forceGet(categoryId);

            const fieldMetadata = FieldMetadata.create({
              category,
              copiedFromFieldId,
              id,
              constituentIds: getConstituentIds(field.calculation()),
              description: description || '',
              dimensions: field
                .dimensionIds()
                .map(dimId => DimensionService.UNSAFE_forceGetById(dimId)),
              // it's serialized as `null` but by convention we only use `undefined`
              sourceName: sourceName || undefined,
            });
            fieldMetadataMappingCache[fieldMetadata.id()] = fieldMetadata;
          },
        );
        resolve(fieldMetadataMappingCache);
      })
      .catch(reject);
  }

  convertURIToID(uri: URI): string {
    return convertURIToID(uri, this.apiVersion, this.endpoint);
  }

  convertIDToURI(id: string): URI {
    return convertIDToURI(id, this.apiVersion, this.endpoint);
  }

  update(fieldMetadata: FieldMetadata): Promise<void> {
    return this._httpService.patch(
      API_VERSION.V2,
      `query/field_metadata/${fieldMetadata.id()}`,
      fieldMetadata.serialize(),
    );
  }
}

export default (new FieldMetadataService(APIService): FieldMetadataService);
